import { Button, CircularProgress, Container, Divider, Grid, Typography } from '@mui/material';
import { WorkloadStatusIndicator } from '../WorkloadStatusIndicator/WorkloadStatusIndicator';
import {
    GetQumuloClusterStatus,
    GetWorkstationDetails,
    GetWorkstationStatus,
    StartQumuloCluster,
    StartWorkstation,
    StopQumuloCluster,
    StopWorkstation,
    WhitelistIpAddress,
    WorkstationStatus,
} from '../../ServiceStack/ServiceStack.dtos';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ServiceStackClient, WorkstationDetailsAtom, WorkstationLandingLoading } from '../../Recoil/Atoms';
import React, { useEffect, useState } from 'react';
import { publicIpv4 } from 'public-ip';
import { usePrevious } from '../Hooks/Hooks';
import { makeStyles } from '../../Themes/MakeStyles';
import { BRANDS, getBranding } from '../../Themes/Branding';

export interface IWorkstationLandingProps {}

const WorkstationLanding: React.FC<IWorkstationLandingProps> = (props) => {
    const [loading, setLoading] = useRecoilState(WorkstationLandingLoading);
    const client = useRecoilValue(ServiceStackClient);
    const [workstationDetails, setWorkstationDetails] = useRecoilState(WorkstationDetailsAtom);
    const { classes, cx } = useStyles();
    const [workloadStatus, setWorkloadStatus] = useState(WorkstationStatus.None as WorkstationStatus);
    const [qumuloClusterStatus, setQumuloClusterStatus] = useState(WorkstationStatus.None as WorkstationStatus);
    const [ipAddress, setIpAddress] = useState('');
    const [disableButtons, setDisableButtons] = useState(false);
    const [disableQumuloButtons, setdisableQumuloButtonss] = useState(false);
    const previousWorkloadStatus = usePrevious(workloadStatus);
    const previousQumuloClusterStatus = usePrevious(qumuloClusterStatus);

    //ToDo make our own endpoint to do this
    const getIpAddress = async () =>
        await publicIpv4({
            fallbackUrls: ['https://ifconfig.co/ip'],
        });

    const getStopWorkstationDisabled = (workloadStatus: string): boolean => {
        switch (workloadStatus) {
            case WorkstationStatus.Stopped:
                return true;
            case WorkstationStatus.Running:
                return false;
            case WorkstationStatus.None:
            case WorkstationStatus.Stopping:
            case WorkstationStatus.Starting:
            case WorkstationStatus.Modifying:
                return true;
        }
    };

    const getStartWorkstationDisabled = (workloadStatus: string): boolean => {
        switch (workloadStatus) {
            case WorkstationStatus.Stopped:
                return false;
            case WorkstationStatus.Running:
                return true;
            case WorkstationStatus.None:
            case WorkstationStatus.Stopping:
            case WorkstationStatus.Starting:
            case WorkstationStatus.Modifying:
                return true;
        }
    };

    const onLinkCLicked = () => {
        window.open('https://docs.teradici.com/find/product/software-and-mobile-clients');
    };

    const OnChromeOsClicked = () => {
        window.open('https://docs.teradici.com/find/product/cloud-access-software/2022.01/mobile-client-for-android-tablets-and-laptops');
    };

    const onClickStartWorkstation = () => {
        const request = new StartWorkstation();
        client
            .post(request)
            .then((response) => {
                setDisableButtons(true);
            })
            .catch((error) => console.log(error));
    };

    const onClickStartQumuloCluster = () => {
        const request = new StartQumuloCluster();
        client
            .post(request)
            .then((response) => {
                setdisableQumuloButtonss(true);
            })
            .catch((error) => console.log(error));
    };

    const onClickStopQumuloCluster = () => {
        const request = new StopQumuloCluster();
        client
            .post(request)
            .then((response) => {
                setdisableQumuloButtonss(true);
            })
            .catch((error) => console.log(error));
    };

    const onClickStopWorkstation = () => {
        const request = new StopWorkstation();
        client
            .post(request)
            .then((response) => {
                setDisableButtons(true);
            })
            .catch((error) => console.log(error));
    };

    const getWorkstationStatus = () => {
        const request = new GetWorkstationStatus();

        client
            .get(request)
            .then((response) => {
                setWorkloadStatus(response.result);
            })
            .catch((error) => console.log(error));
    };

    const getQumuloClusterStatus = () => {
        const request = new GetQumuloClusterStatus();

        client
            .get(request)
            .then((response) => {
                setQumuloClusterStatus(response.result);
            })
            .catch((error) => console.log(error));
    };

    const handleSetIpAddress = async () => {
        const ip = await getIpAddress();
        setIpAddress(ip);
    };

    const handleWhitelistIpAddress = () => {
        client.post(new WhitelistIpAddress()).then(() => {
            setLoading(false);
        });
    };

    const handleGetWorkstationDetails = () => {
        client.get(new GetWorkstationDetails()).then((response) => {
            setWorkstationDetails(response.result);
        });
    };

    useEffect(() => {
        if (workstationDetails && workstationDetails.qumuloNodes) {
            const interval = setInterval(getQumuloClusterStatus, 15000);

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workstationDetails]);

    useEffect(() => {
        if (previousWorkloadStatus !== workloadStatus) {
            setDisableButtons(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workloadStatus]);

    useEffect(() => {
        if (previousQumuloClusterStatus !== qumuloClusterStatus) {
            setdisableQumuloButtonss(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qumuloClusterStatus]);

    useEffect(() => {
        if (ipAddress !== '') {
            handleWhitelistIpAddress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ipAddress]);

    useEffect(() => {
        handleGetWorkstationDetails();
        handleSetIpAddress();
        getWorkstationStatus();

        const interval = setInterval(getWorkstationStatus, 15000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading ? (
        <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item xs={12}>
                <CircularProgress />
            </Grid>
            <Grid item xs={12}>
                <Typography color={getBranding().DefaultTextColor}>Configuring Security Access</Typography>
            </Grid>
        </Grid>
    ) : (
        <>
            <Container maxWidth={'md'}>
                <Grid container justifyContent='center'>
                    <Grid className={cx(classes.HeaderContainer)} item xs={workstationDetails?.qumuloNodes ? 7 : 12}>
                        {process.env.REACT_APP_BRANDING === BRANDS.Atelier ? (
                            <div>
                                <Typography
                                    className={cx(classes.AtelierText, classes.AtelierVegaText)}
                                    color={getBranding().DefaultTextColor}
                                    variant={'h3'}
                                >
                                    Vega
                                </Typography>
                                <Typography className={cx(classes.AtelierText)} color={getBranding().Theme.palette.primary.main} variant={'h3'}>
                                    atelier
                                </Typography>
                            </div>
                        ) : (
                            <Typography variant={'h3'}>WorkRemote Workstation</Typography>
                        )}
                        <Grid item xs={12} className={cx(classes.WorkloadStatusContainer)}>
                            <Typography color={getBranding().DefaultTextColor} variant={'body1'}>
                                Workstation Status
                            </Typography>
                            <WorkloadStatusIndicator className={cx(classes.OneRemSpacingLeft)} workloadStatus={workloadStatus} />
                        </Grid>
                        <Grid item xs={12} className={cx(classes.StartStopButtonContainer)}>
                            <Button
                                onClick={onClickStartWorkstation}
                                disabled={getStartWorkstationDisabled(workloadStatus) || disableButtons}
                                variant={'contained'}
                                color={'primary'}
                            >
                                START WORKSTATION
                            </Button>
                            <Button
                                onClick={onClickStopWorkstation}
                                disabled={getStopWorkstationDisabled(workloadStatus) || disableButtons}
                                variant={'contained'}
                                color={'secondary'}
                                className={cx(classes.OneRemSpacingLeft)}
                            >
                                STOP WORKSTATION
                            </Button>
                        </Grid>
                    </Grid>
                    {workstationDetails?.qumuloNodes && (
                        <Grid item xs={5}>
                            <Grid item xs={12} className={cx(classes.WorkloadStatusContainer, classes.QumuloClusterStatusContainer)}>
                                <Typography color={getBranding().DefaultTextColor} variant={'body1'}>
                                    Qumulo Cluster Status
                                </Typography>
                                <WorkloadStatusIndicator className={cx(classes.OneRemSpacingLeft)} workloadStatus={qumuloClusterStatus} />
                            </Grid>
                            <Grid item xs={12} className={cx(classes.StartStopButtonContainer)}>
                                <Button
                                    onClick={onClickStartQumuloCluster}
                                    disabled={getStartWorkstationDisabled(qumuloClusterStatus) || disableQumuloButtons}
                                    variant={'contained'}
                                    color={'primary'}
                                >
                                    START CLUSTER
                                </Button>
                                <Button
                                    onClick={onClickStopQumuloCluster}
                                    disabled={getStopWorkstationDisabled(qumuloClusterStatus) || disableQumuloButtons}
                                    variant={'contained'}
                                    color={'secondary'}
                                    className={cx(classes.OneRemSpacingLeft)}
                                >
                                    STOP CLUSTER
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={7}>
                        <Typography color={getBranding().DefaultTextColor} variant={'h6'}>
                            Workstation Access Information
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography color={getBranding().DefaultTextColor} variant={'h6'}>
                            Details
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Grid className={cx(classes.WorkStationAccessButtons)} item xs={12}>
                            <Button onClick={onLinkCLicked} color={'primary'}>
                                WINDOWS
                            </Button>
                            <Button onClick={onLinkCLicked} color={'primary'} className={cx(classes.OneRemSpacingLeft)}>
                                LINUX
                            </Button>
                            <Button onClick={onLinkCLicked} color={'primary'} className={cx(classes.OneRemSpacingLeft)}>
                                MAC
                            </Button>
                            <Button onClick={OnChromeOsClicked} color={'primary'} className={cx(classes.OneRemSpacingLeft)}>
                                ChromeOS
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color={getBranding().DefaultTextColor} variant={'body1'}>
                                Clicking one of the links above will open a new web browser tab/window allowing you to download your Teradici PCoIP
                                client.
                            </Typography>
                            <Typography color={getBranding().DefaultTextColor} className={cx(classes.OneRemSpacingTop)} variant={'body1'}>
                                When the client has completed downloading, click it to launch your client.
                            </Typography>
                        </Grid>
                        <Divider className={cx(classes.Divider)} variant='fullWidth' />
                        <Typography color={getBranding().DefaultTextColor}>{`Host Address or Code: ${
                            workstationDetails && workstationDetails.hostAddress
                        }`}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid item xs={12}>
                            <Typography color={getBranding().DefaultTextColor} variant={'body1'}>
                                {workstationDetails && workstationDetails.workstationDescription}
                            </Typography>
                        </Grid>
                        <Grid className={cx(classes.OneRemSpacingTop)} item xs={12}>
                            <Typography
                                color={getBranding().DefaultTextColor}
                                variant={'body1'}
                            >{`White Listed IP Address: ${ipAddress}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    AtelierVegaText: {
        marginRight: '.75rem',
    },
    AtelierText: {
        display: 'inline-block',
    },
    WorkloadStatusContainer: {
        marginTop: '.5rem',
        display: 'flex',
    },
    QumuloClusterStatusContainer: {
        marginTop: '7.5rem',
    },
    StartStopButtonContainer: {
        display: 'flex',
        marginTop: '2.5rem',
    },
    OneRemSpacingLeft: {
        marginLeft: '1rem',
    },
    OneRemSpacingTop: {
        marginTop: '1rem',
    },
    HeaderContainer: {
        marginBottom: `${process.env.REACT_APP_BRANDING !== BRANDS.Atelier ? '10rem' : '1rem'}`,
    },
    DivFlex: {
        display: 'flex',
    },
    WorkStationType: {
        marginLeft: '.25rem',
        fontWeight: 600,
    },
    WorkStationAccessButtons: {
        marginBottom: '3.5rem',
    },
    Divider: {
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
    },
}));

export { WorkstationLanding };
